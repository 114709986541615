<template>
	<div v-if="canPrint">
		<v-flex v-if="simpleButtonDisplay && !listTileDisplay" shrink>
			<w-btn :flat="flat" icon="print" mini v-bind="$attrs" @click="print()"> {{ $t('actions.print') }}</w-btn>
		</v-flex>
		<v-list-tile v-else-if="listTileDisplay" avatar @click="print()">
			<v-list-tile-avatar>
				<v-icon color="primary">print</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'actions.print'" />
			</v-list-tile-content>
		</v-list-tile>
	</div>
</template>

<script>
import printJS from 'print-js'

import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'

export default {
	name: 'PrintButton',
	mixins: [DocumentActionModuleGuard],
	props: {
		version: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: [Array, Object],
			required: false,
			default: () => []
		},
		flat: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	computed: {
		canPrint: function () {
			return this.printableDocuments.length > 0
		},
		printableDocuments: function () {
			let result = []
			const nodes = Array.isArray(this.value) ? this.value : [this.value]
			const documents = nodes.filter(node => !node.is_folder)
			const handledDocuments = documents.filter(document => this.getPrintType(document) != null)
			if ((handledDocuments.length == 1 && this.version) || handledDocuments.every(document => document.latestVersion != null)) {
				result.push(...handledDocuments)
			}
			return result
		}
	},
	methods: {
		getPrintType: function (document) {
			let result = null
			switch (document?.type) {
				case 'image/png':
				case 'image/gif':
				case 'jpg':
				case 'jpeg':
				case 'image/jpeg':
				case 'image/jpg':
				case 'image/svg+xml':
				case 'tiff':
				case 'image/tiff':
					result = 'image'
					break
				case 'html':
				case 'text/html':
					result = 'html'
					break
				case 'txt':
				case 'text':
				case 'text/csv':
				case 'text/plain':
				case 'text/xml':
				case 'text/css':
					result = 'raw-html'
					break
				case 'json':
				case 'application/json':
					result = 'json'
					break
				case 'pdf':
				case 'application/pdf':
				case 'application/pdf-a':
					result = 'pdf'
					break
			}
			if (!result && document?.name) {
				switch (document.name.toLowerCase().split('.').pop()) {
					case 'json':
						result = 'json'
						break
					case 'csv':
					case 'xml':
					case 'css':
					case 'txt':
						result = 'raw-html'
						break
					case 'pdf':
						result = 'pdf'
						break
					case 'htm':
					case 'html':
						result = 'html'
						break
					case 'jpg':
					case 'gif':
					case 'jfi':
					case 'jfif':
					case 'jif':
					case 'jpe':
					case 'jpeg':
					case 'jpg65':
					case 'pg':
					case 'png':
					case 'svg':
					case 'tif':
					case 'tiff':
					case 'webp':
						result = 'image'
						break
				}
			}
			return result
		},
		print: function () {
			let printables
			if (this.printableDocuments.length == 1 && this.version) {
				printables = [
					{
						documentType: this.getPrintType(this.printableDocuments[0]),
						uri: this.version.uri
					}
				]
			} else {
				printables = this.printableDocuments.map(document => {
					return {
						documentType: this.getPrintType(document),
						uri: document.latestVersion.uri
					}
				})
			}
			printables.forEach(({ documentType, uri }) => {
				const url = new URL(uri)
				url.searchParams.set('download', true)
				url.searchParams.set('hash', Date.now())
				printJS({
					printable: url,
					type: documentType,
					showModal: true,
					modalMessage: this.$t('actions.retrieve_document')
				})
			})
		}
	}
}
</script>
